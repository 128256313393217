<template>
    <v-card>
        <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>

        <!-- Edited users-->
        <v-card-text>
            <!--            <editorTest />-->
            <div
                v-if="isOnlyView"
            >
                <purposeItemSingle
                    :purpose="item"
                />

                <!--              showing a video-->
                <!--                <div style="display: flex; justify-content: center; max-width:100%; max-height:480px">
                    <video
                        width="100%"
                        style="max-width:854px; height: auto;"
                        controls
                        autobuffer
                        poster="https://irisglobalback.digitabilities.com/videos/poster_tumision.png"
                    >
                        <source
                            src="https://irisglobalback.digitabilities.com/videos/construye_tu_mision.mp4"
                            type="video/mp4"
                        >
                        Su navegador no soporta Videos HTML
                    </video>
                </div>-->
            </div>
            <purposeVisualEditor
                v-if="!isOnlyView"
                :type-editor="1"
                :body="editedItem.body.value"
                :words-to-select="wordsToSelect"
                @isValid="isValid = $event"
                @update:body="editedItem.body.value = $event"
            />

            <v-row v-if="urlMain === '/purposesCloud' && !isOnlyView">
                <v-col>
                    <span>
                        {{ $t('purposes.mustBeSimilar') }}
                    </span>
                </v-col>
            </v-row>
        </v-card-text>

        <v-card-actions>
            <v-spacer />
            <v-btn
                color="primary"
                text
                @click="$router.go(-1)"
            >
                {{ !isOnlyView ? $t('common.confirmDialogCancel') : $t('common.endProcess') }}
            </v-btn>
            <v-btn
                v-if="!isOnlyView"
                color="primary"
                text
                :disabled="!isValid && !serverCheck"
                @click="save"
            >
                {{ $t('common.dialogSave') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import axios from '@/plugins/axios'
/* import rules from '@/helpers/rulesValidate' */
import { mapGetters } from 'vuex'

import purposeVisualEditor from '@/components/purpose/form/purposeVisualEditor'

import purposeItemSingle from '@/components/purpose/purposeItemSingle'
import postMixin from '@/mixins/postMixin'

export default {
    name: 'PurposeCreate',
    components: { purposeVisualEditor, purposeItemSingle },
    mixins: [postMixin],
    data: function () {
        return {
            urlMain: '/purposes',
            /* rules: {
                ...rules
            }, */
            item: null,
            editedItem: this.getEditedItem(),
            isValid: true,
            serverCheck: false,
            isOnlyView: false,
            /* editorCustomToolbar: '#toolbar', */
            editorSettings: {
                modules: {
                    toolbar: '#toolbar',
                    imageDrop: true,
                    imageResize: {}

                }
            },
            dialogWords: false,
            wordsToSelect: [],
            typeSelected: 1,
            quill: {}
        }
    },
    computed: {
        formTitle() {
            if (this.item) {
                return this.isOnlyView ? this.$t('purposes.view') : this.urlMain === "/purposesCloud" ? this.$t('purposes.editCloud') : this.$t('purposes.edit')
            } else return this.$t('purposes.add')
        }
        // ...mapGetters(['role', 'cities'])
    },
    created() {
        const itemTemp = this.$route.params?.item;

        if (this.$route.params?.isPurposeCloud) {
            this.urlMain = "/purposesCloud"
        }

        if (itemTemp) {
            this.item = JSON.parse(itemTemp);
            this.editedItem = this.getEditedItem();
        }

        this.fetchWords();
    },
    mounted() {
        // console.log('******* this.getImages ****');

        if (this.urlMain === "/purposesCloud" && !this.item) {
            this.$router.go(-1);
        }
    },
    methods: {

        getEditedItem() {
            const item = this.item;

            var empty = !item

            return {
                id: empty ? '' : item.id,
                /* title: {
                    value: empty ? '' : item.attributes.title,
                    error: ''
                }, */
                body: {
                    value: empty ? '' : item.attributes.body,
                    error: ''
                }
            }
        },
        initialize() {

        },
        async fetchWords() {
            try {
                const words = await axios
                    .get('words')

                /* console.log('***** words.data ****')
                console.log(words.data.data) */

                this.wordsToSelect = words.data.data;
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        },
        async save() {
            if (!this.editedItem.body.value) {
                this.$store.dispatch('snackbar/error', this.$t('purposes.requiredBodies'));
                return;
            }

            this.$store.dispatch('loading/start');

            var dataSubmit = {
                data: {
                    type: this.urlMain.substring(1),
                    attributes: {
                        body: this.editedItem.body.value,
                        order: 100
                    }
                }
            };

            let urlAppend = ''
            let method = 'post'
            if (this.editedItem.id) {
                urlAppend = '/' + this.editedItem.id
                dataSubmit.data.id = '' + this.editedItem.id

                method = 'patch'
            }

            try {
                const result = await axios({
                    method: method,
                    url: this.urlMain + urlAppend,
                    data: dataSubmit
                });

                if (result.status === 200 || result.status === 201 || result.status === 204) {
                    this.$store.dispatch('loading/end');
                    this.viewPurpose(result.data.data)

                    const actionMSG = this.editedItem.id ? 'updatedOK' : 'createdOK'
                    this.$store.dispatch('snackbar/success', this.$t('common.' + actionMSG))
                }
            } catch (error) {
                this.$store.dispatch('loading/end');

                let messageType = 0;
                if (error?.response?.status === 422) {
                    if (error.response?.data?.errors?.length) {
                        error.response.data.errors.forEach((e) => {
                            if (e.source.pointer.startsWith('/data/attributes/body')) {
                                // this.editedItem.body.error = e.detail;

                                messageType = 1;
                            }
                        })
                    }
                }

                if (messageType) {
                    let errorText = '';
                    if (this.urlMain === "/purposesCloud") {
                        errorText = this.$t('purposes.mustBeSimilar');
                    } else {
                        errorText = this.$t('purposes.requiredBodies');
                    }

                    this.$store.dispatch('snackbar/error', errorText);
                } else if (error.response.status !== 423) { // Error 423 is that the competition close and is managed globally
                    this.$store.dispatch('snackbar/error', this.$t('common.accionError'))
                }
            }
        },
        viewPurpose(item) {
            this.item = item;
            this.isOnlyView = true;

            /* this.editedItem = {
                id: item.id,
                body: {
                    value: item.attributes.body,
                    error: ''
                }
            } */
        }
    }
}
</script>
