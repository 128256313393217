<template>
    <v-row>
        <v-col
            cols="12"
        >
            <v-card
                v-if="purpose"
                color="dark"
                dark
                class="rounded-xl"
            >
                <purposeText :purpose="purpose" />

                <v-divider />

                <v-row>
                    <v-col
                        class="col-auto pl-6"
                    >
                        <purpose-date :purpose="purpose" />
                    </v-col>

                    <!--                    <v-spacer />
                    <v-col
                        class="col-auto mx-2 my-0"
                        style="display: flex; justify-content: end"
                    >
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon

                                    class="mr-2"
                                    v-bind="attrs"
                                    @click="editItem()"
                                    v-on="on"
                                >
                                    mdi-pencil
                                </v-icon>
                            </template>

                            <span>{{ $t('common.edit') }}</span>
                        </v-tooltip>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon

                                    v-bind="attrs"
                                    @click="deleteItem()"
                                    v-on="on"
                                >
                                    mdi-delete
                                </v-icon>
                            </template>

                            <span>{{ $t('common.delete') }}</span>
                        </v-tooltip>
                    </v-col>-->
                </v-row>

                <!--              Showing the like and dislike quantity of the purpose cloud-->
                <!--                <v-divider />

                <v-row>
                    <v-col
                        class="col-auto pl-6 ml-6"
                    >
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    class="mr-2"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    mdi-thumb-up
                                </v-icon>

                                {{ purpose.attributes.likes || 0 }}
                            </template>

                            <span>{{ $t('purposes.likes') }}</span>
                        </v-tooltip>
                    </v-col>

                    <v-spacer />

                    <v-col
                        class="col-auto ml-2 mr-6 pr-6 my-0"
                        style="display: flex; justify-content: end"
                    >
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                {{ purpose.attributes.dislikes || 0 }}

                                <v-icon
                                    class="ml-2"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    mdi-thumb-down
                                </v-icon>
                            </template>

                            <span>{{ $t('purposes.dislikes') }}</span>
                        </v-tooltip>
                    </v-col>
                </v-row>-->
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import axios from '@/plugins/axios'
import purposeText from '@/components/purpose/purposeText'
import purposeDate from '@/components/purpose/purposeDate'

export default {
    name: 'PurposeItemSingle',
    components: { purposeText, purposeDate },
    props: {
        purpose: {
            type: Object,
            default() {
                return null;
            }
        }
    },
    data() {
        return {

        }
    },
    created() {

    },
    methods: {
        /* async deleteItem(item = this.purpose) {

            if (
                await this.$root.$children[0].$refs.confirm.open(
                    this.$t('common.confirmTitle'),
                    this.$t('common.confirmMSGDelete')
                )
            ) {
                this.$emit('delete:purpose', item);
            }
        },

        editItem() {
            const item = JSON.stringify(this.purpose);
            this.$router.push({ name: 'PurposeCreate', params: { item, isPurposeCloud: 'true' } });
        } */
    }
}
</script>

<style scoped>

</style>
